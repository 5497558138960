<template>
  <div class="agm">

    <b-row class="d-flex align-items-center mb-3">
      <b-col cols="12" md="4">
        <b-img fluid rounded src="/images/agm.png" alt="AGM" class="mb-3 mb-md-0" />
      </b-col>
      <b-col cols="12" md="8">
        <h1>Cash Box Credit Union</h1>
        <h2>Notice of Annual General Meeting</h2>
        <h4>For the year until 30 September 2023</h4>
        <h4>Wednesday 28th February 2024 at 1pm</h4>
      </b-col>
    </b-row>

    <b-card no-body class="p-3 mb-3">
      <blockquote class="blockquote mb-0">
        <p class="mb-0">Cash Box Credit Union is a <span class="font-weight-bold">member owned co-operative</span> so it is important that, as a
          Cash Box member, you come to the AGM to <span class="font-weight-bold">have your say</span> and <span class="font-weight-bold">meet your elected member</span>
        representatives.</p>
        <footer class="blockquote-footer">The Board of Directors</footer>
      </blockquote>
    </b-card>

    <p>You will need to register before the meeting by visiting the GoToWebinar website <a href="https://attendee.gotowebinar.com/register/7303242541583152726" target="_blank">here</a>.</p>
    <p>You simply enter your First Name, Last Name, Email &amp; Membership Number, and click ‘Register’.</p>
    <p>We look forward to seeing you at the AGM</p>
    <p>Yours sincerely</p>
    <p class="font-italic mb-0">The Board of Directors</p>
    <p class="font-weight-bold">Cash Box Credit Union</p>

    <hr/>

    <h2>Register your place</h2>
    <p><a href="https://attendee.gotowebinar.com/register/7303242541583152726" target="_blank" class="btn btn-success">CLICK HERE</a> to Register your attendance before the meeting.</p>
    <p>If you wish to give your apologies or ask any questions in advance please email <a href="mailto:enquiries@cashbox.org.uk?subject=AGM%20apologies">enquiries@cashbox.org.uk</a> putting ‘AGM apologies’ in the subject line. Remember to give your name and member number.</p>

    <p>Your statement is ready to view in your member area.</p>
    <p>To view a statement of your account, <a href="https://cashbox.cuaccount.com/login/" target="_blank">log into your member area</a> where you’ll see all the accounts you hold with Cash Box.</p>
    <p>You can then select Print Statement to download if you wish to do so.</p>
    <p> If you require a paper or emailed statement, please email <a href="mailto:enquiries@cashbox.org.uk?subject=Statement">enquiries@cashbox.org.uk</a>.</p>

    <b-card-group deck class="mb-3">
      <b-card class="text-center">
        <h4 class="card-title">Annual General Meeting 2024</h4>
        <h6 class="card-subtitle mb-2 text-muted">Notification Letter to Members</h6>
        <a href="/agm/2024/AGM-2024-Notification-to-members.pdf" target="_blank" class="btn btn-success">View</a>
      </b-card>
      <b-card class="text-center">
        <h4 class="card-title">Financial Services Compensation Scheme</h4>
        <h6 class="card-subtitle mb-2 text-muted">Fact Sheet</h6>
        <a href="/agm/2024/Financial-Services-Compensation-Scheme-Information.pdf" target="_blank" class="btn btn-success">View</a>
      </b-card>
      <b-card class="text-center">
        <h4 class="card-title">A letter from<br/>our Chair</h4>
        <h6 class="card-subtitle mb-2 text-muted">Annual Newsletter</h6>
        <a href="/agm/2024/AGM-Newsletter-2024-A-letter-from-our-Chair.pdf" target="_blank" class="btn btn-success">View</a>
      </b-card>
    </b-card-group>

    <b-card-group deck class="mb-3">
      <b-card class="text-center">
        <h4 class="card-title">Annual General Meeting Agenda 2024</h4>
        <h6 class="card-subtitle mb-2 text-muted">Agenda</h6>
        <a href="/agm/2024/AGM-2024-Agenda.pdf" target="_blank" class="btn btn-success">View</a>
      </b-card>
      <b-card class="text-center">
        <h4 class="card-title">Annual General Meeting Minutes 2023</h4>
        <h6 class="card-subtitle mb-2 text-muted">Minutes</h6>
        <a href="/agm/2024/AGM-Minutes-Cash-Box-2023.pdf" target="_blank" class="btn btn-success">View</a>
      </b-card>
      <b-card class="text-center">
        <h4 class="card-title">Cash Box Final Accounts 2022-2023</h4>
        <h6 class="card-subtitle mb-2 text-muted">Final Accounts</h6>
        <a href="/agm/2024/Cash-Box-Final-Accounts-2022-2023.pdf" target="_blank" class="btn btn-success">View</a>
      </b-card>
    </b-card-group>

  </div>
</template>

<script>
export default {
  name: 'AGM',
  metaInfo() {
    return {
      title: 'AGM | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
